<template>
    <div v-loading.fullscreen.lock="login_loading">
        <el-dialog
            :visible.sync="NoticeChatFormVisible"
            :append-to-body="true"
            width="900px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeNoticeChatDialog()"
        >
            <div>
                <el-descriptions
                    class="margin-top"
                    :column="2"
                    :labelStyle="{'width':'120px','text-align':'center'}"
                    :contentStyle="{'text-align': 'center'}"
                    border
                >
                    <el-descriptions-item :span=2>
                        <template slot="label">
                            <span style="font-family: HarmonyOS_Sans_Black;font-weight: bold;">帖子标题</span>
                        </template>
                        <span style="font-family: HarmonyOS_Sans_Black;font-weight: bold;">《{{ notice_data.width_title }}》</span>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="notice_data.reply_content"  :span=2>
                        <template slot="label">
                            <span style="font-family: HarmonyOS_Sans_Black;">玩家回复</span>
                        </template>
                        <pre style="font-family: HarmonyOS_Sans_Black;white-space:pre-wrap">{{ notice_data.reply_content }}</pre>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="notice_data.chat_content"  :span=2>
                        <template slot="label">
                            <span style="font-family: HarmonyOS_Sans_Black;">你的回复</span>
                        </template>
                        <pre style="font-family: HarmonyOS_Sans_Black;white-space:pre-wrap">{{ notice_data.width_cotent }}</pre>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="notice_data.chat_content"  :span=2>
                        <template slot="label">
                            <span style="font-family: HarmonyOS_Sans_Black;">玩家评论</span>
                        </template>
                        <pre style="font-family: HarmonyOS_Sans_Black;white-space:pre-wrap">{{ notice_data.chat_content }}</pre>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span v-if="notice_data.reply_content" style="font-family: HarmonyOS_Sans_Black;">回复人</span>
                            <span v-if="notice_data.chat_content" style="font-family: HarmonyOS_Sans_Black;">评论人</span>
                        </template>
                        <span style="font-family: HarmonyOS_Sans_Black;">{{ notice_data.create_staff_name}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span v-if="notice_data.reply_content" style="font-family: HarmonyOS_Sans_Black;">回复时间</span>
                            <span v-if="notice_data.chat_content" style="font-family: HarmonyOS_Sans_Black;">评论时间</span>
                        </template>
                        <span style="font-family: HarmonyOS_Sans_Black;">{{ notice_data.create_time}}</span>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <el-form
                :model="NoticeChatForm"
                ref="NoticeChatForm"
                label-width="100px"
                size="small"
            >
                <common-emoji-component @AddEmoji="e => NoticeChatForm.content+=e"></common-emoji-component>
                <el-input
                    type="textarea"
                    :rows="5"
                    v-model="NoticeChatForm.content"
                    :maxlength="300"
                    show-word-limit
                    clearable
                    placeholder='友善发言，文明评论'
                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                ></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="success"
                    plain
                    @click="submitNoticeChat()"
                    >回复</el-button
                >
                <el-button
                    plain
                    @click="closeNoticeChatDialog()"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonEmojiComponent from '@/components/common/CommonEmojiComponent'

import { ForumChatRequest } from '@/network/forum.js'

export default {
    name:'',
    data(){
        return {
            login_loading: false,
            NoticeChatForm: {
                content: ''
            }
        }
    },
    computed:{},
    methods:{
        closeNoticeChatDialog() {
            this.NoticeChatForm = {
                content: ''
            }
            this.$emit('ExitNoticeChatDialog')
        },
        submitNoticeChat() {
            if(this.NoticeChatForm.content === '') {
                this.$message.error('请填写回复')
                return
            }

            this.login_loading = true
            this.NoticeChatForm.width_content_id = this.notice_data.with_content_id
            ForumChatRequest(this.NoticeChatForm)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('回复成功!')
                        this.closeNoticeChatDialog()
                        this.$emit('refresh')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{
        NoticeChatFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        notice_data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        CommonEmojiComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>
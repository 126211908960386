<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <img v-if="$store.state.menuFormVisible" :src="$store.state.baseURL+'icon/xiangshang.png'" height="25px" class="jiantoutubiao" @click="jiantouclk">
            <img v-else :src="$store.state.baseURL+'icon/xiangxia.png'" height="25px" class="jiantoutubiao" @click="jiantouclk">
            <el-badge v-if="notice_num" :value="notice_num" :max="99" class="seeach_bar">
                <img :src="$store.state.baseURL+'icon/tongzhi.png'" height="25px" class="jiantoutubiao" @click="$emit('ShowNotice')">
            </el-badge>
            <img v-else :src="$store.state.baseURL+'icon/tongzhi.png'" height="25px" class="jiantoutubiao" @click="$emit('ShowNotice')">
            
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="form.title"
                    placeholder="标题搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="发贴人" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="发贴人搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="type"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >发贴</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'ForumSearchBarComponent',
    data() {
        return {
            form: {
                title: '',
                name: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        jiantouclk() {
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
        }
    },
    filters: {},
    props: {
        type: {
            type: Number | String,
            default() {
                return ''
            }
        },
        notice_num: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less'>
.box-card .el-form-item {
    margin-bottom: 3px;
}
.temp_add {
    float: right;
    margin-bottom: 10px;
}
.jiantoutubiao  {
    margin-right: 10px;
    cursor: pointer;
    transition: 0.5s;
}
.seeach_bar .el-badge__content.is-fixed {
    right: 20px
}
</style>
<template>
    <div v-loading.fullscreen.lock="loading">
        <el-collapse-transition>
            <el-card class="box-card" v-show="$store.state.menuFormVisible">
                <el-descriptions
                    :column="3"
                    :labelStyle="{'text-align':'center','background-color':'#FFFFFF','padding-bottom':'0px'}"
                    :contentStyle="{'text-align': 'left','background-color':'#FFFFFF','padding-bottom':'0px'}"
                    :colon="false"
                >
                    <el-descriptions-item>
                        <template slot="label">
                            <div  @click="zonghebankuai">
                                <img :src="$store.state.baseURL+'icon/wodetiezi.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">综合板块</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[0]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[0]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[0]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="youxigonggao">
                                <img :src="$store.state.baseURL+'icon/youxigonggao.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">游戏公告</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[1]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[1]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[1]['last_reply_time']}}</span></div>
                        </div>
                        
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="huodongzhuanqu">
                                <img :src="$store.state.baseURL+'icon/huodongzhuanqu.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">活动专区</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[2]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[2]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[2]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="mengxinqiuzhu">
                                <img :src="$store.state.baseURL+'icon/mengxinqiuzhu.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">萌新求助</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[3]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[3]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[3]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="juesetaolun">
                                <img :src="$store.state.baseURL+'icon/juesetaolun.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">角色讨论</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[4]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[4]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[4]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="wanfajiaoliu">
                                <img :src="$store.state.baseURL+'icon/wanfajiaoliu.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">玩法交流</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[5]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[5]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[5]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="wanjiadiy">
                                <img :src="$store.state.baseURL+'icon/wanjiadiy.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">玩家DIY</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[6]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[6]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[6]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div @click="fankuijianyi">
                                <img :src="$store.state.baseURL+'icon/fankuijianyi.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">反馈建议</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[7]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[7]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[7]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <div  @click="xianliaochigua">
                                <img :src="$store.state.baseURL+'icon/xianliaochigua.png'" height="60px" class="gongnengtubiao">
                                <div class="font_hm">闲聊吃瓜</div>
                            </div>
                        </template>
                        <div>
                            <div style="margin-top: 10px;"><span style="font-family: HarmonyOS_Sans_Light;">贴子数量：{{last_data[8]['count']}}</span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃玩家：<span>{{last_data[8]['last_reply_user']}}</span></span></div>
                            <div><span style="font-family: HarmonyOS_Sans_Light;">活跃时间：{{last_data[8]['last_reply_time']}}</span></div>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
        </el-collapse-transition>
        <forum-search-bar-component
            :type="cond.type"
            :notice_num="notice_num"
            @search="get_forum_title_index"
            @addTeam="AddFormVisible = true"
            @ShowNotice="NoticeFormVisible = true"
        ></forum-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                highlight-current-row
                :show-header="false"
            >
                <el-table-column
                    align="center"
                    prop="hot"
                    label="热度"
                    width="55px"
                >
                    <template slot-scope="scope">
                        <div>
                            <img v-if="(scope.row.hot + scope.row.see)>99" :src="$store.state.baseURL+'icon/redu5.png'" height="25px" style="vertical-align:middle;">
                            <img v-else-if="(scope.row.hot + scope.row.see)>49" :src="$store.state.baseURL+'icon/redu4.png'" height="25px" style="vertical-align:middle;">
                            <img v-else-if="(scope.row.hot + scope.row.see)>29" :src="$store.state.baseURL+'icon/redu3.png'" height="25px" style="vertical-align:middle;">
                            <img v-else-if="(scope.row.hot + scope.row.see)>9" :src="$store.state.baseURL+'icon/redu2.png'" height="25px" style="vertical-align:middle;">
                            <img v-else-if="(scope.row.hot + scope.row.see)>0" :src="$store.state.baseURL+'icon/redu1.png'" height="25px" style="vertical-align:middle;">
                        </div>
                        <div>
                            <span style="font-family: HarmonyOS_Sans_Light;;">{{ scope.row.hot + scope.row.see }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="left"
                    prop="title"
                    label="标题"
                    min-width="500px"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <img v-if="scope.row.top" :src="$store.state.baseURL+'icon/zhiding.png'" height="25px" style="vertical-align:middle;">
                        <img v-if="scope.row.nice" :src="$store.state.baseURL+'icon/jingpin.png'" height="25px" style="vertical-align:middle;">
                        <span @click="details(scope.row.id)" style="color: #2828FF;font-family: HarmonyOS_Sans_Light;font-size: 20px;cursor: pointer;transition: 0.5s;">{{ scope.row.title }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="left"
                    prop="create_staff_name"
                    label="发布人"
                    width="300px"
                >
                    <template slot-scope="scope">
                        <div style="color: #9F35FF;font-family: HarmonyOS_Sans_Light;">
                            <img :src="$store.state.baseURL+'icon/fabu.png'" height="14px">
                            <span style="margin-left: 10px;">{{ scope.row.create_time }}</span>
                            <span style="margin-left: 5px;">{{ scope.row.create_staff_name }}</span>
                        </div>
                        <div style="font-family: HarmonyOS_Sans_Light;">
                            <img :src="$store.state.baseURL+'icon/huifu.png'" height="14px">
                            <span style="margin-left: 10px;">{{ scope.row.last_reply_time }}</span>
                            <span style="margin-left: 5px;">{{ scope.row.last_reply_user_name }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_forum_title_index"
        ></common-page-component>
        <forum-add-component
            :type="cond.type"
            :AddFormVisible="AddFormVisible"
            :details_data="details_data"
            :edit_type="edit_type"
            @exitAddFormDialog="exitAddFormDialog"
            @search="get_forum_title_index"
        ></forum-add-component>
        <forum-dialog-component
            :ForumDialogVisible="ForumDialogVisible"
            :details_data="details_data"
            @EditTitle="EditTitle"
            @refresh="refresh"
            @search="get_forum_title_index"
            @ExitForumDialog="ForumDialogVisible = false"
        ></forum-dialog-component>
        <notice-dialog-component
            :NoticeFormVisible="NoticeFormVisible"
            :table_data="notice_table_data"
            :total="notice_ftotal"
            @search_notice="get_notice_index"
            @read_notice="read_notice"
            @ExitNoticeDialog="NoticeFormVisible =false"
        ></notice-dialog-component>
    </div>
</template>

<script>
import ForumSearchBarComponent from '@/components/forum/ForumSearchBarComponent'
import ForumAddComponent from '@/components/forum/ForumAddComponent'
import NoticeDialogComponent from '@/components/forum/NoticeDialogComponent'
import ForumDialogComponent from '@/components/forum/ForumDialogComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

import { ForumIndexRequest,ForumdetailsRequest,ForumNoticeIndexRequest,ForumNoticeNumRequest,ForumNoticeReadRequest } from '@/network/forum.js'

export default {
    name:'',
    data(){
        return {
            ForumDialogVisible: false,
            AddFormVisible: false,
            NoticeFormVisible: false,
            loading: false,
            last_data:[
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'综合板块'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'游戏公告'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'活动专区'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'萌新求助'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'角色讨论'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'玩法交流'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'玩家DIY'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'反馈建议'},
                {count:0,last_reply_user:'',last_reply_time:'',type_name:'闲聊吃瓜'}
            ],
            ftotal: 0,
            table_data: [],
            cond: {
                type: '',
                title: '',
                name: '',
                page: 1,
                limit: 10
            },
            details_data: {},
            timer: false,
            notice_table_data: [],
            notice_ftotal: 0,
            notice_num: 0,   //未读通知数量
            edit_type: 0
        }
    },
    computed:{},
    methods:{
        zonghebankuai() {
            this.cond.type=''
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        youxigonggao() {
            this.cond.type=1
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        huodongzhuanqu() {
            this.cond.type=2
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        mengxinqiuzhu() {
            this.cond.type=3
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        juesetaolun() {
            this.cond.type=4
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        wanfajiaoliu() {
            this.cond.type=5
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        wanjiadiy() {
            this.cond.type=6
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        fankuijianyi() {
            this.cond.type=7
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        xianliaochigua() {
            this.cond.type=8
            this.$store.commit('edit_menuFormVisible', !this.$store.state.menuFormVisible)
            this.get_forum_title_index()
        },
        get_forum_title_index(param={}) {
            this.loading = true
            this.cond.title = param.title ?? this.cond.title
            this.cond.name = param.name ?? this.cond.name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            ForumIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.ftotal = s.result.total
                        this.last_data = s.result.last
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally(()=>this.get_notice_index())

            //滚动条回顶部
            this.$nextTick(() => {
                document.getElementById('el_main').scrollTop = 0;
            })
        },
        details(details_id) {
            this.ForumDialogVisible = true
            ForumdetailsRequest(details_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh(details_id) {
            this.get_forum_title_index()
            this.details(details_id)
        },
        get_notice_index(param = {limit:10,page:1}) {
            this.loading = true
            ForumNoticeIndexRequest(param)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.notice_table_data = s.result.data
                        this.notice_ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally(()=>this.get_notice_num())
        },
        get_notice_num() {
            ForumNoticeNumRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.notice_num = s.result.num
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        read_notice(notice_id) {
            ForumNoticeReadRequest(notice_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.get_notice_index()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        EditTitle() {
            this.edit_type = 1
            this.AddFormVisible = true
        },
        exitAddFormDialog() {
            this.edit_type =  0
            this.AddFormVisible = false
        },
        start_timer() {
            this.timer = true
            const changeTime = setInterval(() => {
                if(this.timer) {
                    ForumIndexRequest(this.cond)
                        .then((s) => {
                            if (s.status === 0) {
                                this.table_data = s.result.data
                                this.last_data = s.result.last
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                } else {
                    clearInterval(changeTime)
                }
            }, 5000)
        },
        end_timer() {
            this.timer = false
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_forum_title_index()
        // this.start_timer()
    },
    beforeDestroy() {
        this.end_timer()
    },
    mounted(){},
    updated(){},
    components:{
        ForumSearchBarComponent,
        CommonPageComponent,
        ForumAddComponent,
        ForumDialogComponent,
        NoticeDialogComponent
    },
    watch:{},
}
</script>

<style lang='less' scoped>
.font_hm {
    font-family: HarmonyOS_Sans_Black;
    color: #0080FF;
    margin-bottom: 10px;
    font-weight: bold;
}
.gongnengtubiao  {
    cursor: pointer;
    transition: 0.5s;
}
.gongnengtubiao:not(.turned):hover {
    transform: scale(1.3);
    filter: none;
}
</style>
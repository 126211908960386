<template>
    <div v-loading.fullscreen.lock="loading">
        <el-dialog
            title="通知中心"
            :visible.sync="NoticeFormVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitNoticeDialog')"
        >
            <el-table
                :data="table_data"
                style="width: 100%"
                highlight-current-row
                :header-cell-style="{'color':'#2828FF','font-family': 'HarmonyOS_Sans_Black'}"
            >
                <el-table-column
                    align="center"
                    prop="read"
                    label=""
                    width="55px"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.read" style="font-family: HarmonyOS_Sans_Black;">已读</span>
                        <span v-else style="color: #46A3FF;font-weight: bold;font-family: HarmonyOS_Sans_Black;">未读</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="width_cotent"
                    label="你的标题或回复"
                    min-width="500px"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span style="cursor: pointer;transition: 0.5s;font-family: HarmonyOS_Sans_Black;" @click="details(scope.row)">
                            <span v-if="scope.row.read">
                                <span v-if="scope.row.reply_content">《{{ scope.row.width_title }}》</span>
                                <span v-else-if="scope.row.chat_content">{{ scope.row.width_cotent }}</span>
                                <span v-else>{{ scope.row.width_cotent }}</span>
                            </span>
                            <span v-else style="color: #46A3FF;font-weight: bold;">
                                <span v-if="scope.row.reply_content">《{{ scope.row.width_title }}》</span>
                                <span v-else-if="scope.row.chat_content">{{ scope.row.width_cotent }}</span>
                                <span v-else>{{ scope.row.width_cotent }}</span>
                            </span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="create_staff"
                    label="动态"
                    width="300px"
                >
                    <template slot-scope="scope">
                        <span style="cursor: pointer;transition: 0.5s;font-family: HarmonyOS_Sans_Black;" @click="details(scope.row)">
                            <span v-if="scope.row.read">
                                <span v-if="scope.row.reply_content">【{{ scope.row.create_staff_name}}】回复了你的贴子</span>
                                <span v-else-if="scope.row.chat_content">【{{ scope.row.create_staff_name}}】评论了你的回复</span>
                                <span v-else>【{{ scope.row.create_staff_name}}】给你的回复点赞</span>
                            </span>
                            <span v-else style="color: #46A3FF;font-weight: bold;">
                                <span v-if="scope.row.reply_content">【{{ scope.row.create_staff_name}}】回复了你的贴子</span>
                                <span v-else-if="scope.row.chat_content">【{{ scope.row.create_staff_name}}】评论了你的回复</span>
                                <span v-else>【{{ scope.row.create_staff_name}}】给你的回复点赞</span>
                            </span>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="cond.page"
                    :page-sizes="this.$store.state.pageSizes"
                    :page-size="cond.limit"
                    layout='total, sizes, prev, pager, next, jumper'
                    :total="total"
                >
                </el-pagination>
            </div>
            <notice-chat-dialog-component
                :NoticeChatFormVisible="NoticeChatFormVisible"
                :notice_data="notice_data"
                @refresh="refresh"
                @ExitNoticeChatDialog="NoticeChatFormVisible = false"
            ></notice-chat-dialog-component>
        </el-dialog>
    </div>
</template>

<script>
import NoticeChatDialogComponent from '@/components/forum/NoticeChatDialogComponent'

export default {
    name:'',
    data(){
        return {
            loading: false,
            cond: {
                limit: 10,
                page: 1
            },
            NoticeChatFormVisible: false,
            notice_data: {}
        }
    },
    computed:{},
    methods:{
        details(row) {
            if(row.read === 0) {
                this.$emit('read_notice',row.id)
            }
            
            if((row.reply_content==='') && (row.chat_content==='')) {
                return
            }
            
            this.notice_data = row
            this.NoticeChatFormVisible = true
        },
        handleSizeChange(val) {
            this.cond.limit = val
            this.$emit('search_notice', this.cond)
        },
        handleCurrentChange(val) {
            this.cond.page = val
            this.$emit('search_notice', this.cond)
        },
        refresh() {
            this.$emit('search_notice', this.cond)
        }
    },
    filters:{},
    props:{
        NoticeFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        table_data: {
            type: Array,
            default() {
                return []
            }
        },
        total: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        NoticeChatDialogComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>
<template>
    <div v-loading.fullscreen.lock="loading">
        <el-dialog
            :visible.sync="ForumDialogVisible"
            :append-to-body="true"
            width="90%"
            :center="true"
            top="20px"
            :close-on-click-modal="false"
            :before-close="() => closeForumDialog()"
        >
            <template v-slot:title>
                <span style="color: #2828FF;font-family: HarmonyOS_Sans_Light;">{{ titie_content.title }}</span>
            </template>
            <hr/>
            <el-table
                :data="titie_content.content"
                style="width: 100%"
                :show-header="false"
                :cell-style="{'vertical-align':'top'}"
            >
                <el-table-column
                    align="center"
                    prop="user_name	"
                    label="回复人"
                    width="200px"
                >
                    <template slot-scope="scope">
                        <div>
                            <div style="position: relative;">
                                <img v-if="scope.row.user_frame" :src="scope.row.user_frame" height="200px" width="150px" alt="" style="position:absolute;top:0;">
                                <img v-if="scope.row.user_dynamics" :src="scope.row.user_dynamics" height="200px" width="150px" alt="">
                                <img v-else :src="scope.row.user_skin" height="200px" width="150px" alt="">
                            </div>
                            <div style=";color: #2894FF;text-align: center;font-family: HarmonyOS_Sans_Light;" v-if="scope.row.user_gender === 1">
                                <img :src="$store.state.baseURL+'icon/xingbienan.png'" height="16px">
                                {{scope.row.user_name}}
                            </div>
                            <div style=";color: #FF60AF;text-align: center;font-family: HarmonyOS_Sans_Light;" v-else-if="scope.row.user_gender === 2">
                                <img :src="$store.state.baseURL+'icon/xingbienv.png'" height="16px">
                                {{scope.row.user_name}}
                            </div>
                            <div style="margin-top:10px;font-family: HarmonyOS_Sans_Light;">
                                <div>
                                    <img :src="$store.state.baseURL+'icon/junxian.png'" height="16px">
                                    <span style="margin-left:5px;color: #9F35FF;">{{scope.row.junxian}}</span>
                                </div>
                                <div>
                                    <img :src="$store.state.baseURL+'icon/gongxianzhi.png'" height="16px">
                                    <span style="margin-left:5px;">贡献值：{{scope.row.contribute}}</span>
                                </div>
                                <div>
                                    <img :src="$store.state.baseURL+'icon/huoyuedu.png'" height="16px">
                                    <span style="margin-left:5px;">活跃度：{{scope.row.forum_exp}}</span>
                                </div>
                            </div>
                            
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="left"
                    prop="content"
                    label="内容"
                    min-width="500px"
                >
                    <template slot-scope="scope">
                        <div style="font-family: HarmonyOS_Sans_Light;position: relative;">
                            <span>
                                <span v-if="scope['$index']">{{ scope['$index']+'#' }}</span>
                                <span v-else style="color: #CE0000;">楼主</span>
                                &nbsp;<span>发表于{{ scope.row.create_time}}</span>
                            </span>
                            <span v-if="scope['$index'] > 0" style="position:absolute;right:0;cursor: pointer;transition: 0.5s;">
                                <span v-if="!scope.row.is_zan" @click="zan(scope.row.id)" style="color: #CE0000;"><i class="el-icon-lollipop"></i>点赞</span>&nbsp;
                                <span @click="chat(scope.row.id)" style="color: #9F35FF;"><i class="el-icon-chat-dot-square"></i>评论</span>&nbsp;
                                <span v-if="$_has('删除回复')" @click="delContent(scope.row.id)" style="color: #000000;"><i class="el-icon-delete"></i>删除</span>
                            </span>
                            <span v-else style="position:absolute;right:0;cursor: pointer;transition: 0.5s;">
                                <span v-if="!scope.row.is_zan" @click="zan(scope.row.id)" style="color: #CE0000;"><i class="el-icon-lollipop"></i>点赞</span>&nbsp;
                            </span>
                        </div>
                        <hr style="border: 0; border-top: 1px dashed #9F35FF; margin-bottom: 10px;">
                        <div>
                            <div><img v-for="item in scope.row.picture" :key="item.url" :src="item.url" alt="" style="max-width: 100%"></div>
                            <pre class="el-tiptap-editor__content" style="font-family: HarmonyOS_Sans_Light;white-space:pre-wrap" v-html="scope.row.content"></pre>
                            <div v-if="scope.row.hide_content">
                                <el-divider><span style="color: #9F35FF;">以下内容回复可见</span></el-divider>
                                <pre v-if="titie_content.hide" class="el-tiptap-editor__content" style="font-family: HarmonyOS_Sans_Light;white-space:pre-wrap" v-html="scope.row.hide_content"></pre>
                                <div v-else style="text-align: center;font-size: 30px;font-weight: bold;color: #d0d0d0;">隐藏内容</div>
                            </div>
                        </div>
                        <div v-if="scope.row.chat.length > 0" style="margin-left: 100px;background-color: #F5FFE8;font-family: HarmonyOS_Sans_Light;padding-bottom: 20px;">
                            <div v-for="item in scope.row.chat" :key="item.time">
                                <span>【{{item.time}}】</span>
                                <span>{{item.name}}</span>
                                <span>：{{item.content}}</span>
                            </div>
                        </div>
                        <div v-if="scope.row.zan" style="margin-left: 100px;background-color: #ECF5FF;font-family: HarmonyOS_Sans_Light;"><span style="color: #2894FF;">{{scope.row.zan}}</span>觉得很赞</div>
                    </template>
                </el-table-column>
            </el-table>
            <hr/>
            
            <el-form
                :model="ReplyForm"
                ref="ReplyForm"
                label-width="100px"
                size="small"
            >
                <common-emoji-component @AddEmoji="e => ReplyForm.content+=e"></common-emoji-component>
                <common-upload-component
                    ref="upload"
                    :limit=3
                    :ffileList="ReplyForm.picture"
                    type='picture'
                ></common-upload-component>
                <el-input
                    type="textarea"
                    :rows="5"
                    v-model="ReplyForm.content"
                    :maxlength="1000"
                    show-word-limit
                    clearable
                    placeholder='为共建良好环境，请文明发言'
                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                ></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="success"
                    plain
                    @click="submitReply()"
                    >发表回复</el-button
                >
                <el-button
                    v-if="$_has('贴子置顶')"
                    type="primary"
                    plain
                    @click="submitTop()"
                >
                    <span v-if="titie_content.top === 0">贴子置顶</span>
                    <span v-else>取消置顶</span>
                </el-button>
                <el-button
                    v-if="$_has('贴子加精')"
                    type="warning"
                    plain
                    @click="submitNice()"
                >
                    <span v-if="titie_content.nice === 0">贴子加精</span>
                    <span v-else>取消加精</span>
                </el-button>
                <el-button
                    v-if="$_has('修改贴子') && ($store.state.user.id === titie_content.create_staff)"
                    type="info"
                    plain
                    @click="editTitle()"
                    >修改贴子</el-button
                >
                <el-button
                    v-if="$_has('删除贴子')"
                    type="danger"
                    plain
                    @click="delTitle()"
                    >删除贴子</el-button
                >
                <el-button
                    plain
                    @click="closeForumDialog()"
                    >关闭</el-button
                >
            </div>
            <chat-dialog-component
                :chatDialogVisible="chatDialogVisible"
                :width_content_id="width_content_id"
                @refresh="$emit('refresh',titie_content.id)"
                @ExitChatDialog="ExitChatDialog"
            ></chat-dialog-component>
        </el-dialog>
    </div>
</template>

<script>
import chatDialogComponent from '@/components/forum/chatDialogComponent'
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEmojiComponent from '@/components/common/CommonEmojiComponent'

import {
    ForumReplyRequest,
    ForumTopRequest,
    ForumNiceRequest,
    ForumDelTitleRequest,
    ForumDelContentRequest,
    ForumZanRequest
} from '@/network/forum.js'

export default {
    name:'',
    data(){
        return {
            loading: false,
            chatDialogVisible: false,
            width_content_id: 0,
            titie_content: {},
            ReplyForm: {
                content: '',
                picture: []
            }
        }
    },
    computed:{},
    methods:{
        closeForumDialog() {
            this.ReplyForm = {
                content: '',
                picture: []
            }
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            this.$emit('ExitForumDialog')
        },
        ExitChatDialog() {
            this.width_content_id = 0;
            this.chatDialogVisible = false
        },
        chat(width_content_id) {
            this.width_content_id = width_content_id;
            this.chatDialogVisible = true
        },
        delContent(width_content_id) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ForumDelContentRequest(width_content_id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('refresh',this.titie_content.id)
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        submitReply() {
            if(this.ReplyForm.content === '') {
                this.$message.error('请填写回复内容')
                return
            }

            this.loading = true
            this.ReplyForm.picture = this.$refs.upload.fileList
            this.ReplyForm.width_title_id = this.titie_content.id
            ForumReplyRequest(this.ReplyForm)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('回复成功!')
                        this.closeForumDialog()
                        this.$emit('refresh',this.titie_content.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        submitTop() {
            this.loading = true
            ForumTopRequest(this.titie_content.id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('操作成功!')
                        this.closeForumDialog()
                        this.$emit('refresh',this.titie_content.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        submitNice() {
            this.loading = true
            ForumNiceRequest(this.titie_content.id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('操作成功!')
                        this.closeForumDialog()
                        this.$emit('refresh',this.titie_content.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        editTitle() {
            this.$emit('EditTitle')
            this.closeForumDialog()
        },
        delTitle() {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ForumDelTitleRequest(this.titie_content.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeForumDialog())
                })
                .catch((err) => {})
        },
        zan(width_content_id) {
            this.loading = true
            ForumZanRequest(width_content_id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('操作成功!')
                        this.closeForumDialog()
                        this.$emit('refresh',this.titie_content.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{
        ForumDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        details_data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        chatDialogComponent,
        CommonUploadComponent,
        CommonEmojiComponent
    },
    watch:{
        details_data(newVal) {
            this.titie_content = newVal
        }
    }
}
</script>

<style lang='less' scoped>
</style>
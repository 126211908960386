<template>
    <div>
        <el-dialog
            :visible.sync="AddFormVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => closeAddFormDialog('AddForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="AddForm"
                    ref="AddForm"
                    label-width="100px"
                    size="small"
                >
                    <div>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>标题</span>
                                </template>
                                <el-input
                                    v-model="AddForm.title"
                                    :maxlength="50"
                                    show-word-limit
                                    clearable
                                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                                ></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>内容</span>
                                </template>
                                <common-editor-component
                                    v-if="$_has('贴吧颜文字')"
                                    ref="tbeditor"
                                    :fcontent="AddForm.content"
                                ></common-editor-component>
                                <el-input
                                    v-else
                                    type="textarea"
                                    :rows="20"
                                    v-model="AddForm.content"
                                    :maxlength="1000"
                                    show-word-limit
                                    clearable
                                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                                ></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>图片</span>
                                </template>
                                <common-upload-component
                                    v-if="AddForm.picture"
                                    ref="upload"
                                    :limit=3
                                    :ffileList="AddForm.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="hide">
                                <template slot="label">
                                    <div>隐藏</div>
                                </template>
                                <common-editor-component
                                    v-if="$_has('贴吧颜文字')"
                                    ref="yctbeditor"
                                    :fcontent="AddForm.hide_content"
                                ></common-editor-component>
                                <el-input
                                    v-else
                                    type="textarea"
                                    :rows="20"
                                    v-model="AddForm.hide_content"
                                    :maxlength="1000"
                                    show-word-limit
                                    clearable
                                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                                ></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div style="margin-top: 10px;"><el-checkbox v-model="hide">增加隐藏内容（回复可见）</el-checkbox></div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="edit_type"
                    type="primary"
                    plain
                    @click="submitEdit('AddForm')"
                    >修改</el-button
                >
                <el-button
                    v-else
                    type="success"
                    plain
                    @click="submitInsert('AddForm')"
                    >发布</el-button
                >
                <el-button
                    plain
                    @click="closeAddFormDialog('AddForm')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'

import { ForumAddRequest,ForumEditRequest } from '@/network/forum.js'

export default {
    name: 'ForumAddComponent',
    data() {
        return {
            login_loading: false,
            AddForm: {
                type: '',
                title: '',
                content: '',
                hide_content: '',
                picture: []
            },
            hide: false
        }
    },
    computed: {},
    methods: {
        closeAddFormDialog(formName) {
            this.AddForm = {
                type: '',
                title: '',
                content: '',
                hide_content: '',
                picture: []
            }
            this.hide = false
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            if(this.$_has('贴吧颜文字')) {
                this.$refs.tbeditor.content = ''
                if(this.hide) {
                    this.$refs.yctbeditor.content = ''
                }
            }
            this.$emit('exitAddFormDialog')
        },
        submitInsert(formName) {

            if(this.$_has('贴吧颜文字')) {
                this.AddForm.content = this.$refs.tbeditor.content
                if(this.hide) {
                    this.AddForm.hide_content = this.$refs.yctbeditor.content
                }
            }

            if((this.AddForm.title === '') || (this.AddForm.content === '')) {
                this.$message.error('请填写标题和内容')
                return
            }
            this.login_loading = true
            this.AddForm.picture = this.$refs.upload.fileList 
            this.AddForm.type = this.type
            ForumAddRequest(this.AddForm)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.closeAddFormDialog()
                        this.$emit('exitAddFormDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        submitEdit(formName) {

            if(this.$_has('贴吧颜文字')) {
                this.AddForm.content = this.$refs.tbeditor.content
                if(this.hide) {
                    this.AddForm.hide_content = this.$refs.yctbeditor.content
                }
            }

            if((this.AddForm.title === '') || (this.AddForm.content === '')) {
                this.$message.error('请填写标题和内容')
                return
            }
            this.login_loading = true
            this.AddForm.picture = this.$refs.upload.fileList 
            ForumEditRequest(this.AddForm)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.closeAddFormDialog()
                        this.$emit('exitAddFormDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {
        AddFormVisible: Boolean,
        type: {
            type: Number | String,
            default() {
                return ''
            }
        },
        details_data: {
            type: Object,
            default() {
                return {}
            }
        },
        edit_type: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonEditorComponent
    },
    watch: {
        type(newVal) {
            this.AddForm.type = newVal
        },
        edit_type(newVal) {
            if(newVal) {
                this.AddForm = {
                    id: this.details_data.id,
                    type: this.details_data.type,
                    title: this.details_data.title,
                    content: this.details_data.content[0].content,
                    hide_content: this.details_data.content[0].hide_content,
                    picture: this.details_data.content[0].picture,
                }
                this.hide = this.details_data.content[0].hide_content ?　true : false
            } else {
                this.AddForm = {
                    type: '',
                    title: '',
                    content: '',
                    hide_content: '',
                    picture: []
                }
            }
        }
    },
}
</script>

<style lang='less' scoped></style>
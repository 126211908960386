<template>
    <div v-loading.fullscreen.lock="loading">
        <el-dialog
            :visible.sync="chatDialogVisible"
            :append-to-body="true"
            width="500px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeChatDialog()"
        >
            <el-form
                :model="ChatForm"
                ref="ChatForm"
                label-width="100px"
                size="small"
            >
                <common-emoji-component @AddEmoji="e => ChatForm.content+=e"></common-emoji-component>
                <el-input
                    type="textarea"
                    :rows="5"
                    v-model="ChatForm.content"
                    :maxlength="300"
                    show-word-limit
                    clearable
                    placeholder='友善发言，文明评论'
                    style="font-family: HarmonyOS_Sans_Black;font-weight: bold;"
                ></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="success"
                    plain
                    @click="submitChat()"
                    >评论</el-button
                >
                <el-button
                    plain
                    @click="closeChatDialog()"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonEmojiComponent from '@/components/common/CommonEmojiComponent'

import { ForumChatRequest } from '@/network/forum.js'

export default {
    name:'',
    data(){
        return {
            loading: false,
            ChatForm: {
                content: ''
            }
        }
    },
    computed:{},
    methods:{
        closeChatDialog() {
            this.ChatForm = {
                content: ''
            }
            this.$emit('ExitChatDialog')
        },
        submitChat() {
            if(this.ChatForm.content === '') {
                this.$message.error('请填写评论')
                return
            }

            this.loading = true
            this.ChatForm.width_content_id = this.width_content_id
            ForumChatRequest(this.ChatForm)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('评论成功!')
                        this.closeChatDialog()
                        this.$emit('refresh')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{
        chatDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        width_content_id: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        CommonEmojiComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>
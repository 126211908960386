<template>
    <el-tooltip placement="top" effect="light">
        <template v-slot:content>
            <picker
                :include="['people','nature','foods','activity','places','Symbols']"
                :showSearch="false"
                :showPreview="false"
                :showCategories="true"
                @select="addEmoji"
            />
        </template>
        <img :src="$store.state.baseURL+'icon/weixiao.png'" style="height: 20px;">
    </el-tooltip>
</template>

<script>
import { Picker } from "emoji-mart-vue"; //引入组件

export default {
    name:'',
    data(){
        return {}
    },
    computed:{},
    methods:{
        addEmoji(e) {
            this.$emit('AddEmoji',e.native)
        }
    },
    filters:{},
    props:{},
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        Picker
    },
    watch:{},
}
</script>

<style lang='less'>
.emoji-mart[data-v-7bc71df8] {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 420px;
    color: #ffffff !important;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background: #fff;
}
</style>